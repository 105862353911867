import cookieService from './cookieService';
import axios from 'axios';
import requestService from './requestService';

export class SalesPackageCodeService {
  static async checkCode(code) {
    const path = '/rest/sales-package/code/check?code=' + code;

    return requestService.makeGetRequest(path);
  }

  static async useCode(code) {
    const url = process.env.VUE_APP_DOMAIN +
      '/rest/sales-package/code/use';
    const token = cookieService.getCookie('token');
    let config = {};

    if (token) {
      config = {
        headers: {
          Authorization: token
        }
      };
    }

    return await axios.post(
      url,
      {
        code: code
      },
      config
    );
  }
}

const salesPackageCodeService = SalesPackageCodeService;
export default salesPackageCodeService;
