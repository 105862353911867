import axios from 'axios';

export class RegisterService {
  static async register(userIdentity, password, termsAndConditionsAgreement, marketingAgreement) {
    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/user/users',
      {
        email: userIdentity,
        password: password,
        terms_and_conditions_agreement: termsAndConditionsAgreement,
        marketing_agreement: marketingAgreement
      }
    );
  }

  static async logAntiBot(userIdentity, password, termsAndConditionsAgreement, marketingAgreement, antiBot) {
    if (antiBot) {
      return await axios.post(
        process.env.VUE_APP_DOMAIN + '/rest/user/anti-bot',
        {
          email: userIdentity,
          password: password,
          terms_and_conditions_agreement: termsAndConditionsAgreement,
          marketing_agreement: marketingAgreement,
          antiBot: antiBot
        }
      );
    }

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/user/users',
      {
        email: userIdentity,
        password: password,
        terms_and_conditions_agreement: termsAndConditionsAgreement,
        marketing_agreement: marketingAgreement,
        antiBot: antiBot
      }
    );
  }
}

const registerService = RegisterService;
export default registerService;
